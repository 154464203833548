// Основные цвета
$accent: #286E50;
$accent2: #CFD700;


// Минимальная ширина страницы
$minWidth: 320;
// Ширина полотна (макета)
$maxWidth: 1920;
// Ширина ограничивающего контейнера (0 = нет ограничения)
$maxWidthContainer: 1438;
// Общий отступ у контейнера
// (30 = по 15px слева и справа, 0 = нет отступа)
$containerPadding: 30;

// Тип адаптива:
// 1 = отзывчивость (у контейнера нет брейкпоинтов),
// 2 = по брейк-поинтам (контейнер меняет свою ширину по брейк-поинтам)
$responsiveType: 1;

// Ширина страбатывания первого брейкпоинта
$containerWidth: $maxWidthContainer + $containerPadding;

// Брейк-поинты
$pc: em($containerWidth); // ПК, ноутбуки, некоторые планшеты в горизонтальном положении
$tablet: em(991.98); // Планшеты, некоторые телефоны в горизонтальном положении
$mobile: em(767.98); // Телефоны L
$mobileSmall: em(479.98); // Телефоны S
