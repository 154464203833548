.placeholder-container {
  margin-bottom: 20px;
  position: relative;
}
.placeholder-container__input {
  height: 50px;
}
.placeholder-container__textarea {
  height: 200px;
}
.placeholder-container input, .placeholder-container textarea {
  color: inherit;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  border: none;
  border-bottom: 1px solid #afafaf;
}
.placeholder-container input:focus, .placeholder-container textarea:focus {
  outline: 0;
  outline-offset: 0;
}
.placeholder-container .caption {
  position: absolute;
  transition: all 1s;
  top: 50%;
  z-index: 2;
  transform: translate(0, -50%);
  color: #afafaf;
}
.placeholder-container .caption__active {
  opacity: 1;
  top: 0;
  padding: 0 15px;
  z-index: 3;
  transform: translate(0, -50%);
  color: #CFD700;
  font-size: 14px;
  background-color: transparent;
}
.placeholder-container .error {
  border-bottom: 1px solid red !important;
  border-radius: inherit;
}
@media (max-width: 410px) {
  .placeholder-container {
    margin-bottom: 10px;
  }
  .placeholder-container__input {
    height: 30px;
  }
}

.radio {
  position: relative;
  padding: 4px 0;
}
.radio__input {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
}
.radio__label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.radio__label::before {
  width: 23px;
  height: 23px;
  border: 1px solid #CFCFCF;
  content: "";
  margin-right: 10px;
  transition: all 0.3s;
  border-radius: 50%;
}
.radio__input:checked + .radio__label:before {
  background-color: #286E50;
}

.btn {
  color: #CFD700;
  font-size: 14px;
  cursor: pointer;
  transition: 1s;
  border: none;
  border-radius: 30px;
  text-align: center;
  line-height: 1;
  background-color: #eceff0;
  text-transform: uppercase;
  padding: 16px 30px;
  display: inline-block;
}
.btn--decor {
  position: relative;
  color: #000;
  padding: 15px 80px 15px 20px;
}
.btn--decor::before {
  position: absolute;
  top: 0;
  right: 0;
  width: 67px;
  height: 100%;
  background-color: #CFD700;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  content: "";
}
.btn--decor::after {
  position: absolute;
  right: 27px;
  width: 17px;
  height: 17px;
  background: url("../img/icon/arr-white.png") no-repeat;
  content: "";
}
.btn:hover {
  color: white;
  background-color: #CFD700;
}

.modal-popup {
  --transition-time: 0.3s;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition-time), visibility var(--transition-time);
}
.modal-popup::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.modal-popup [class*=__container] {
  position: relative;
  width: 500px;
  padding: 30px;
  margin: 50px auto;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 10px;
  cursor: default;
}
@media (max-width: 1024px) {
  .modal-popup [class*=__container] {
    width: 90%;
  }
}
.modal-popup__close {
  font-size: 50px;
  font-weight: bold;
  color: #CFD700;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.modal-popup.is-open {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition-time), visibility var(--transition-time);
}
.modal-popup__container.modal-popup-open {
  display: inline-block;
}
.modal-popup .fade {
  opacity: 0;
  transition: opacity var(--transition-time);
}
.modal-popup .fade.animate-open {
  opacity: 1;
  transition: opacity var(--transition-time);
}
.modal-popup .fadeInUp {
  opacity: 0;
  transform: translateY(-100px);
  transition: opacity var(--transition-time), transform var(--transition-time);
}
.modal-popup .fadeInUp.animate-open {
  opacity: 1;
  transform: translateY(0);
  transition: opacity var(--transition-time), transform var(--transition-time);
}
.modal-popup .form-modal {
  text-align: center;
}
.modal-popup .form-modal__title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}
.modal-popup .form-modal .btn {
  margin: 0 auto;
}

.disable-scroll {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
}