// Настройки ===============================================================================================================================================================================================================================================================================================
@import "_base/settings";


// form
.placeholder-container {
    margin-bottom: 20px;
    position: relative;

    &__input {
        height: 50px;
    }

    &__textarea {
        height: 200px;
    }

    input, textarea {
        color: inherit;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
        background-color: transparent;
        border-radius: 0;
        padding: 0;
        border: none;
        border-bottom: 1px solid #afafaf;
        &:focus {
            outline: 0;
            outline-offset: 0;
        }
    }

    .caption {
        position: absolute;
        transition: all 1s;
        top: 50%;
        z-index: 2;
        transform: translate(0, -50%);
        color: #afafaf;

        &__active {
            opacity: 1;
            top: 0;
            padding: 0 15px;
            z-index: 3;
            transform: translate(0, -50%);
            color: $accent2;
            font-size: 14px;
            background-color: transparent;
        }

    }

    .error {
        border-bottom: 1px solid red !important;
        border-radius: inherit;
    }

    @media (max-width: 410px) {
        margin-bottom: 10px;
        &__input {
            height: 30px;
        }
    }





}

.radio {
    position: relative;
    padding: 4px 0;

    // скрываем radio input по default
    &__input {
        -webkit-appearance: none;
        appearance: none;
        position: absolute;
    }

    // создаём свою радио кнопку
    &__label {
        display: flex;
        align-items: center;
        cursor: pointer;
        &::before {
            width: 23px;
            height: 23px;
            border: 1px solid #CFCFCF;
            content: '';
            margin-right: 10px;
            transition: all .3s;
            border-radius: 50%;
        }
    }

    // при checked
    &__input:checked + &__label:before {
        background-color: $accent;
    }


}

.btn {
    color: $accent2;
    font-size: 14px;
    cursor: pointer;
    transition: 1s;
    border: none;
    border-radius: 30px;
    text-align: center;
    line-height: 1;
    background-color: #eceff0;
    text-transform: uppercase;
    padding: 16px 30px;
    display: inline-block;
    &--decor {
        position: relative;
        color: #000;
        padding: 15px 80px 15px 20px;
        &::before {
            position: absolute;
            top: 0;
            right: 0;
            width: 67px;
            height: 100%;
            background-color: $accent2;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            content: '';
        }
        &::after {
            position: absolute;
            right: 27px;
            width: 17px;
            height: 17px;
            background: url("../img/icon/arr-white.png") no-repeat;
            content: '';
        }

    }



    &:hover {
        color: white;
        background-color: $accent2;
    }

}


// модальное окно
.modal-popup {
    --transition-time: 0.3s;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0,0,0, 0.5);
    cursor: pointer;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    opacity: 0; // скрыть
    visibility: hidden; // скрыть
    transition: opacity var(--transition-time), visibility var(--transition-time);

    // вспомогательный элемент скрыть
    &::before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }

    // container модального окна
    [class*=__container] {
        position: relative;
        width: 500px;
        padding: 30px;
        margin: 50px auto;
        // display: none; // скрыть
        vertical-align: middle;
        background-color: #fff;
        border-radius: 10px;
        cursor: default;
        @media (max-width: 1024px) {
            width: 90%;
        }
    }

    // close
    &__close {
        font-size: 50px;
        font-weight: bold;
        color: $accent2;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    // overlay при открытой модалке
    &.is-open {
        opacity: 1;
        visibility: visible;
        transition: opacity var(--transition-time), visibility var(--transition-time);
    }

    // container при открытой модалке
    &__container.modal-popup-open {
        display: inline-block;
    }

    // эффекты
    .fade {
        opacity: 0;
        transition: opacity var(--transition-time);
    }

    .fade.animate-open {
        opacity: 1;
        transition: opacity var(--transition-time);
    }

    .fadeInUp {
        opacity: 0;
        transform: translateY(-100px);
        transition: opacity var(--transition-time), transform var(--transition-time);
    }

    .fadeInUp.animate-open {
        opacity: 1;
        transform: translateY(0);
        transition: opacity var(--transition-time), transform var(--transition-time);
    }

    // form
    .form-modal {
        text-align: center;
        &__title {
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: bold;
        }
        .btn {
            margin: 0 auto;
        }
    }

}

// отключение scroll
.disable-scroll {
    position: relative;
    overflow: hidden;
    height: 100vh;
    width: 100%;
}

